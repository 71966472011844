@import "../../theme/colors.css";

.navbar {
    position: fixed;
    width: 100vw;
    height: 64px;
    background-color: var(--color-accent-200);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
}

.navbar__icon {
    width: 36px;
    height: 36px;
    margin: 16px;
    padding: 0;
    cursor: pointer;
}

.navbar__title {
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
    color: var(--color-accent-800);
    font-size: 16pt;
    font-family: squares-custom, sans-serif;
    /*background: -webkit-linear-gradient(left, #fafafa, #999999);*/
    /*    -webkit-background-clip: text;*/
    /*    -webkit-text-fill-color: transparent;*/
}

.navbar__link {
    text-decoration: none;
}