.name-group {
    display: flex;
    flex-direction: row;
}

.name-field {
    width: 254px;
}

.f {
    padding: 16px;
}
