.auth_form {
    display: flex;
    flex-direction: column;
}

.action_buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.action_buttons_container {
    width: 200px;
}
