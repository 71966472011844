.text-warn {
    color: #ff0000;
    text-decoration: underline;
    font-family: "OCR A Extended", sans-serif;
    font-size: 18px;
    text-align: center;
}

.apps-panel {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: center;
    flex-wrap: wrap;
}

.apps_container {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 1500px;
}

@media only screen and (max-width: 1560px) {
    .apps_container {
        width: 1200px;
    }

    @media only screen and (max-width: 1260px) {
        .apps_container {
            width: 900px;
        }

        @media only screen and (max-width: 960px) {
            .apps_container {
                width: 600px;
            }

            @media only screen and (max-width: 660px) {
                .apps_container {
                    width: 300px;
                }
            }
        }
    }
}

.text-default {
    width: 100%;
    text-align: center;
}