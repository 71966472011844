@import "./../../theme/colors.css";

.log-container {
    width: calc(80vw - 32px);
    /*height: 600px;*/
    overflow: auto;
    background-color: var(--color-accent-200);
    padding: 16px;
    border-radius: 16px;

}

.log-container-error {
    background-color: var(--color-warn-transparent);
    width: calc(100% - 32px);
    padding: 16px;
    border-radius: 16px;
}

.language-log {
    outline: none;
    user-select: text;
    color: var(--color-accent-800);
}

.language-error {
    color: var(--color-warn);
}

.activity-title {
    width: 100%;
    text-align: center;
    font-size: 18pt;
    font-family: squares-custom, sans-serif;
    color: var(--color-accent-800);
}

.fab {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 50;
    background-color: var(--color-accent-800);
    padding: 8px 24px;
    border-radius: 16px;
    transition: 0.2s;
}

.fab:hover {
    background-color: var(--color-accent-900);
    transition: 0.2s;
}

.fab-text {
    text-decoration: none;
    color: var(--color-accent-50);
}