.btn-link {
    margin: 8px;
}

.btn-panel {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;
}