@import "components/theme/colors.css";

* {
    -webkit-tap-highlight-color: transparent;
}

body {
    padding: 0;
    margin: 0;
    font-family: sans-serif;
    background-color: var(--color-accent-0);
    user-select: none;
}

::-webkit-scrollbar {
    width: 32px;
    height: 32px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 16px;
    border: 8px solid transparent;
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
