@import "../../theme/colors.css";

.account_card_link {
    text-decoration: none;
}

.account_card_bg {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;
    background-color: var(--color-accent-200);
    margin-bottom: 16px;
    border-radius: 16px;
    transition: 0.3s;
}


.account_card_bg:hover {
    background-color: var(--color-accent-300);
    transition: 0.3s;
}

.account_card_icon {
    border-radius: 50%;
    background-color: var(--color-accent-400);
    width: 80px;
    height: 80px;
    margin: 24px;
}

.account_legal_name {
    color: var(--color-accent-800);
    font-size: 16pt;
    font-family: ocr-custom, sans-serif;
}

.account_user_name {
    color: rgba(255, 255, 255, 0.9)
}

.account_user_id {
    color: rgba(255, 255, 255, 0.6)
}

.account_card_text_info {
    /* N */
    width: 350px
}