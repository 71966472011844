@import "./../../theme/colors.css";

.server-card {
    background-color: var(--color-accent-200);
    padding: 16px;
    margin: 16px;
    border-radius: 16px;
    width: calc(80vw - 32px);
}

.circle-green {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #8bff87;
}

.circle-red {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ff6a6a;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    opacity: 0;
    transition: 0.2s;
    background-color: var(--color-accent-300);
    color: var(--color-accent-900);
    text-align: center;
    border-radius: 6px;
    padding: 5px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -14px;
    right: 120%;
}

.server-icon {
    width: 32px;
    height: 32px;
}

.tooltip:hover .tooltiptext {
    opacity: 1;
    transition: 0.2s;
}

.non-expandable {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.server-info-non-expandable {
    display: flex;
    flex-direction: row;
    width: calc(100% - 100px);
    gap: 32px;
}

.server-property {
    padding: 0;
    margin: 0;
}

.server-additional-info {
    margin-left: 46px;
}

.server-info-table {
    width: 100%;
}

.server-table-col {
    width: 33%;
    padding: 8px 0;
}

.value {
    color: var(--color-accent-800);
}

.expandable {
    /* x */
}

.hr {
    border: 1px solid var(--color-accent-400);
    border-top: none;
}