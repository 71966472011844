.widgets {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1500px;
    min-width: 300px;
}

.func_type {
    margin-left: 16px;
    margin-right: 36px;
    width: calc(100% - 106px);
    font-size: 18pt;
    font-family: squares-custom, sans-serif;
    color: var(--color-accent-800);
}

.divider {
    width: calc(100% - 72px);
    margin: 0 36px;
    padding: 0;
    border-top: 1px solid var(--color-primary-accent-transparent);
    border-bottom: none;
    border-left: none;
    border-right: none;
}

.apps_container {
    width: 1500px;
    transition: 0.3s;
}

@media only screen and (max-width: 1560px) {
    .apps_container {
        width: 1200px;
        transition: 0.3s;
    }

    @media only screen and (max-width: 1260px) {
        .apps_container {
            width: 900px;
            transition: 0.3s;
        }

        @media only screen and (max-width: 960px) {
            .apps_container {
                width: 600px;
                transition: 0.3s;
            }

            @media only screen and (max-width: 660px) {
                .apps_container {
                    width: 300px;
                    transition: 0.3s;
                }
            }
        }
    }
}

.toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.toggle__icon {
    width: 36px;
    height: 36px;
}

.widget-container {
    background-color: var(--color-accent-100);
    margin-top: 16px;
    border-radius: 16px;
}