.ui__loading-screen_embedded {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ui__loading-screen-spinner_embedded {
    width: 48px;
    height: 48px;
    animation: loading-spinner_embedded 1s linear infinite;
}

@keyframes loading-spinner_embedded {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
