@import "../../theme/colors.css";

.widget__app {
    width: 260px;
    height: 260px;
    border-radius: 24px;
    margin: 20px;
    transition: 0.5s;
    background-color: var(--color-accent-200);
}

.widget__app:hover {
    background-color: var(--color-accent-300);
    transform: translateY(-10px);
    cursor: pointer;
    transition: 0.5s;
    box-shadow: 0 10px 50px var(--color-accent-300);
}

.widget__app:hover .app-title {
    opacity: 1;
    transition: 0.5s;
}

.widget__app:hover .app-desc {
    color: rgba(255, 255, 255, 0.9);
    transition: 0.5s;
}

.widget__app-icon {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: var(--color-primary-accent-transparent);
    margin: 20px auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.widget__app-icon-warn {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: var(--color-warn-transparent);
    margin: 20px auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.widget__app-title {
    text-transform: none;
    width: 100%;
    text-align: center;
    font-family: squares-custom, sans-serif;
    font-size: 20px;
    color: var(--color-accent-800);
    opacity: 0.9;
    transition: 0.5s;
        -webkit-font-smoothing : none;
}

.widget__app-title-warn {
    text-transform: none;
    width: 100%;
    text-align: center;
    font-family: squares-custom, sans-serif;
    font-size: 20px;
    color: var(--color-warn);
    opacity: 0.9;
    transition: 0.5s;
        -webkit-font-smoothing: none;
}

.widget__app-desc {
    text-transform: none;
    width: calc(100% - 32px);
    text-align: center;
    font-size: 12px;
    font-family: sans-serif;
    color: var(--color-secondary-light);
    margin-left: 16px;
    transition: 0.5s;
}

.widget__app-icon-img {
    width: 48px;
    height: 48px;
}

.widget__app-link {
    width: 260px;
    text-decoration: none;
    padding: 0;
    margin: 0;
}
