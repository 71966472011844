@import "../../theme/colors.css";

.button {
    min-width: 200px;
}

.button-frame {
    margin: 16px;
}

.btn-content-outline {
    color: var(--color-accent-800);
}

.btn-content {
    color: var(--color-accent-50);
}

.btn-tonal {
    color: var(--color-accent-800);
}

.btn-mtrl {
    width: 220px;
    text-align: center;
    align-items: center;
    padding: 4px 0 0;
    margin: 0;
    font-family: google-sans, sans-serif;
}
