@import "../../theme/colors.css";

.input {
    width: 540px;
}

.input-frame {
    margin: 16px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 100px var(--color-autofill) inset !important;
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill{
    -webkit-text-fill-color: var(--color-accent-800) !important;
}
