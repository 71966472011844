.error-page {
    text-align: center;
    align-items: center;
    padding: 16px;
}

.error-code {
    width: 100%;
    text-align: center;
    align-items: center;
    font-size: 32pt;
}

.error-title {
    width: 100%;
    text-align: center;
    align-items: center;
    font-size: 20pt;
}

.error-description {
    width: 100%;
    text-align: center;
    align-items: center;
    font-size: 14pt;
}
